






































import PurpleScribeService from "@/services/PurpleScribeService";
import helperService from "@/services/HelperService";

export default {
  components: {},
  props: {
    jobID: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      summaryType: String,
      loading: false
    };
  },
  mounted() {
    this.summaryType = "General"
  },
  methods: {
    async generateSummary() {
      this.loading = true
      try {
        await PurpleScribeService.generateSummary(this.jobID, this.summaryType)
        helperService.showNotfySuccess(this.$toast, `${this.summaryType} summary generated successfully`)
        this.$emit('summary-generated')
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, err.response.data.message);
      } finally {
        this.loading = false
      }
    },
  }
}
